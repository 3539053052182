export default {
  na1: "#7571FF",
  eun1: "#E142A1",
  euw1: "#00E5BF",
  jp1: "F43939",
  kr: "#E3E5EA",
  la1: "#FF5A2D",
  la2: "#995AFF",
  br1: "#00AF41",
  oc1: "#4AC1C9",
  tr1: "#608EA8",
  ru: "#43B1EE",
  sg2: "#E34646",
  vn2: "#F22F2F",
  th2: "#16A68C",
  tw2: "#2C79FF",
  ph2: "#74B058",
};
